@import url(../../common.css);

.btx-row {
  margin: 0 0 4px;
  font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  line-height: 1;
}

.btx-row--primary {
  color: white;
  background-color: var(--brand-color);
}

.btx-row--secondary {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  color: #333;
  background-color: transparent;
  /* box-shadow: rgba(0, 0, 0, 0.15) 0 0 0 1px inset; */
}

.btx-row--small {
  font-size: 12px;
  padding: 4px 0;
}

.btx-row--medium {
  font-size: 14px;
  padding: 4px 0;
}

.btx-row--large {
  font-size: 16px;
  padding: 4px 0;
}

@media screen and (min-width: 768px) {
  .btx-row {
    flex-direction: row;
  }
  .btx-row--small {
    font-size: 12px;
    padding: 10px 16px;
  }
  .btx-row--medium {
    padding: 11px 20px;
  }
  .btx-row--large {
    font-size: 16px;
    padding: 12px 24px;
  }
}
@import url(../../common.css);

.btx-card {
  width: 100%;
  margin: 8px 0 16px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 8px 8px inset;
}

.btx-card--primary {
  color: white;
  background-color: var(--brand-color);
}

.btx-card--secondary {
  color: var(--text-color-primary);
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 0 1px inset;
}

.btx-card--small {
  font-size: 12px;
  padding: 8px;
}

.btx-card--medium {
  font-size: 14px;
  padding: 8px;
}

.btx-card--large {
  font-size: 16px;
  padding: 8px;
}

@media screen and (min-width: 768px) {
  .btx-card--small {
    padding: 10px 16px;
  }
  .btx-card--medium {
    padding: 11px 20px;
  }
  .btx-card--large {
    padding: 12px 24px;
  }
}

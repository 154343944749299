@import url(../../common.css);

.btx-switch {
  font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 0;
  border-radius: 3em;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
}

.btx-switch--primary {
  color: white;
  background-color: var(--brand-color);
}

.btx-switch--secondary {
  color: #333;
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 0 1px inset;
}

.btx-switch--small {
  font-size: 12px;
  padding: 10px 16px;
}

.btx-switch--medium {
  font-size: 14px;
  padding: 11px 20px;
}

.btx-switch--large {
  font-size: 16px;
  padding: 12px 24px;
}

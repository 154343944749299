@import url(../../common.css);

.btx-button {
  font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: 0 16px 0 0;
  border: 0;
  border-radius: 3em;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  transition: 0.15s ease-out;
  background-size: 200%;
  background-position: right;
}

.btx-button--primary {
  color: white;
  /* background-color: var(--brand-color); */
  background-image: linear-gradient(
    to left,
    var(--brand-color) 50%,
    rgb(204, 0, 83) 50%
  );
}

.btx-button--primary:hover {
  background-position: left;
}

.btx-button--secondary {
  color: var(--text-color-primary);
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 0 1px inset;
}

.btx-button--small {
  font-size: 12px;
  padding: 10px 16px;
}

.btx-button--medium {
  font-size: 14px;
  padding: 11px 20px;
}

.btx-button--large {
  font-size: 16px;
  padding: 12px 24px;
}

@import url(../../common.css);

.btx-label {
  width: 100%;
  margin: 4px 4px 8px;
  /* font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; */
  font-family: Gilroy, Noto Sans CJK TC, Noto Sans TC, Helvetica, Arial,
    sans-serif;
  font-weight: 400;
  border-bottom: 1px solid var(--brand-color);
  cursor: pointer;
  display: inline-block;
  line-height: 1;
}

.btx-label--primary {
  color: white;
  background-color: var(--brand-color);
}

.btx-label--secondary {
  color: var(--text-color-primary);
  background-color: transparent;
}

.btx-label--small {
  font-size: 12px;
  padding: 4px;
}

.btx-label--medium {
  font-size: 14px;
  padding: 4px;
}

.btx-label--large {
  font-size: 16px;
  padding: 4px;
}

.btx-label--subsection {
  width: auto;
  border: 0;
  font-size: 16px;
  font-weight: 700;
  color: var(--secondary-primary-200);
}

.btx-label--section {
  width: 100%;
  display: flex;
  align-items: center;
  border: 0;
  border-left: 4px solid var(--secondary-primary-100);
  font-size: 1.2rem;
}

@media screen and (min-width: 768px) {
  .btx-label {
    /* width: 240px; */
  }
  .btx-label--small {
    padding: 10px 16px;
  }
  .btx-label--medium {
    padding: 11px 24px;
  }
  .btx-label--large {
    padding: 12px 24px;
  }
}

@media screen and (min-width: 990px) {
  .btx-label {
    width: 240px;
  }
}